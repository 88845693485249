
import { postJson , get, post} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // ID获取书本封面,章数量,单词数量
    async getBookImgAndInfo(context, params) {
      const res = await get('/Study/aiWord/getBookImgAndInfo', params)
      return res
    },
    // 获取学生章节信息
    async getStudentWordInfo(context, params) {
      const res = await get('/Study/aiWord/getStudentWordInfo', params)
      return res
    },
    // 获章节单词列表
    async getWordInfoByCatalog(context, params) {
      const res = await get('/Study/aiWord/getWordInfoByCatalog', params)
      return res
    },
    // 获取背-单词试题
    async getAiWordBeiQuestions(context, params) {
      const res = await postJson('/Study/aiWord/getAiWordBeiQuestions', params)
      return res
    },
    // 获取练-单词试题
    async getAiWordLianShitiByGuid(context, params) {
      const res = await postJson('/Study/aiWord/getAiWordLianShitiByGuid', params)
      return res
    },
    // 获取测-单词试题
    async getAiWordCeShiti(context, params) {
      const res = await postJson('/Study/aiWord/getAiWordCeShiti', params)
      return res
    },
    // 背单词提交
    async submitAiWordBei(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordBei', params)
      return res
    },
    // 背单词提交-单次
    async submitAiWordBeiDanci(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordBeiDanci', params)
      return res
    },
    // 获取所有单词解析
    async getWordShitiAndTimeByGuid(context, params) {
      const res = await get('/Study/aiWord/getWordShitiAndTimeByGuid', params)
      return res
    },
    // 添加生词本
    async addWordNotebook(context, params) {
      const res = await post('/Study/aiWord/addWordNotebook', params)
      return res
    },
    // 单词删除生词本
    async removeWordNotebook(context, params) {
      const res = await post('/Study/aiWord/removeWordNotebook', params)
      return res
    },
    // 添加章节信息
    async insertWordCategory(context, params) {
      const res = await post('/Study/aiWord/insertWordCategory', params)
      return res
    },
    // 查看生词本
    async getWordNotebook(context, params) {
      const res = await get('/Study/aiWord/getWordNotebook', params)
      return res
    },
    // 查看单词图谱
    async getWordTupu(context, params) {
      const res = await get('/Study/aiWord/getWordTupu', params)
      return res
    },
    // 背单词保存进度
    async saveWordGuid(context, params) {
      const res = await post('/Study/aiWord/saveWordGuid', params)
      return res
    },
    // 查询用户是否有保存的进度
    async getWordSaveGuid(context, params) {
      const res = await get('/Study/aiWord/getWordSaveGuid', params)
      return res
    },
    // 查询用户保存的试题
    async getSaveGuidShitiByGuid(context, params) {
      const res = await get('/Study/aiWord/getSaveGuidPingXieShitiByGuid', params)
      return res
    },
    // 重新学习清除保存进度
    async delectSaveGuid(context, params) {
      const res = await get('/Study/aiWord/delectSaveGuid', params)
      return res
    },
    // 获取Ai背单词(背)试题(新版 自动分配章节单词)
    async getAiWordBeiQuestionsNew(context, params) {
      const res = await postJson('/Study/aiWord/getAiWordBeiQuestionsNew', params)
      return res
    },
    // 获取Ai背单词(学)试题 (展示背做错的题)
    async getAiWordXueShitiByGuid(context, params) {
      const res = await postJson('/Study/aiWord/getAiWordXueShitiByGuid', params)
      return res
    },
    // 获取Ai背单词(练)试题 （自动分配）
    async getAiWordCeShitiNew(context, params) {
      const res = await postJson('/Study/aiWord/getAiWordCeShitiNewByType', params)
      // const res = await postJson('/Study/aiWord/getAiWordCeShitiNew', params)
      return res
    },
    // 获取Ai背单词(背)单次提交 （自动分配）
    async submitAiWordBeiDanciNew(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordBeiDanciNew', params)
      return res
    },
    // 获取Ai背单词(背)总提交 （自动分配）
    async submitAiWordBeiNew(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordBeiNew', params)
      return res
    },
    // 获取Ai背单词(练)单次提交 （自动分配）
    async submitAiWordLianDanciNew(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordLianDanciNew', params)
      return res
    },
    // 获取Ai背单词(练)最后一次提交 （自动分配）
    async submitAiWordLianNew(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordLianNew', params)
      return res
    },
    // Ai背单词(练)提交答案(单次提交) (自动分配章节)  2024-03-18 题型修改为英文选义
    async submitAiWordLianDanciUpdata(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordLianDanciUpdata', params)
      return res
    },
    // Ai背单词(练)提交答案(总提交) (自动分配章节)  2024-03-18 题型修改为英文选义
    async submitAiWordLianUpdata(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordLianUpdata', params)
      return res
    },
    // 获取学生词库列表详情
    async getWordLibraryList(context, params) {
      const res = await get('/Study/aiZiZhuWord/getWordLibraryList', params)
      return res
    },
     // 获取Ai背单词(学)试题 (2024-08-06 学生自主背单词展示所有试题)
     async getShitiByXue(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/getShitiByXue', params)
      return res
    },
    // 根据guid获取测保存进度的试题 ,2024-08-06 学生自主背单词
    async getShitiBySaveGuid(context, params) {
      const res = await get('/Study/aiZiZhuWord/getShitiBySaveGuid', params)
      return res
    },
    // 获取Ai背单词(练)拼写试题 2024-04-12 (拼写试题)
    async getAiWordPingXieShiti(context, params) {
      const res = await postJson('/Study/aiWord/getAiWordPingXieShiti', params)
      return res
    },
    // 获取Ai背单词(练)单次提交 （自动分配）
    async submitAiWordLianDanciPingXie(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordLianDanciPingXie', params)
      return res
    },
    // 获取Ai背单词(练)最后一次提交 （自动分配）
    async submitAiWordLianPingXie(context, params) {
      const res = await postJson('/Study/aiWord/submitAiWordLianPingXie', params)
      return res
    },
    // 按计划拿单词
    async getPlanWordBeiQuestions(context, params) {
      const res = await postJson('/Study/aiWord/getPlanWordBeiQuestions', params)
      return res
    },
    // 自主背单词报告
    async getWordReportLianList(context, params) {
      const res = await get('/Study/aiZiZhuWord/getWordReportLianList', params)
      return res
    },
    // 自主背单词章节报告
    async getCategoryWordReportLianList(context, params) {
      const res = await get('/Study/aiZiZhuWord/getCategoryWordReportLianList', params)
      return res
    },
    // 自主背单词报告详情
    async getWordTaskReportTaskInfo(context, params) {
      const res = await get('/Study/aiZiZhuWord/getWordTaskReportTaskInfo', params)
      return res
    },
    // 自主背单词报告概览
    async getWordTaskReportGaiLian(context, params) {
      const res = await get('/Study/aiZiZhuWord/getWordTaskReportGaiLian', params)
      return res
    },
    // 自主背单词报告根据guid 获取报告基础数据 任务详情
    async getWordTaskReportData(context, params) {
      const res = await get('/Study/aiZiZhuWord/getWordTaskReportData', params)
      return res
    },
    // 自主背单词报告根据guid 获取报告用户单词综合详情
    async getWordTaskReportUserWordInfo(context, params) {
      const res = await get('/Study/aiZiZhuWord/getWordTaskReportUserWordInfo', params)
      return res
    },
    // 获取需要复习的单词数
    async getIsHaveReview(context, params) {
      const res = await get('/Study/aiZiZhuWord/getIsHaveReview', params)
      return res
    },
    // 复习是否有保存进度
    async getReviewWordSaveGuid(context, params) {
      const res = await get('/Study/aiZiZhuWord/getReviewWordSaveGuid', params)
      return res
    },
    // 复习测的拿题
    async getReviewWordQuestions(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/getReviewWordQuestions', params)
      return res
    },
    // 复习保存进度拿题
    async getShitiByReviewGuid(context, params) {
      const res = await post('/Study/aiZiZhuWord/getShitiByReviewGuid', params)
      return res
    },
    // 复习保存进度
    async saveReviewWordGuid(context, params) {
      const res = await post('/Study/aiZiZhuWord/saveReviewWordGuid', params)
      return res
    },
    // 复习测单词提交
    async submitReviewWordBeiDanci(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/submitReviewWordBeiDanci', params)
      return res
    },
    async submitReviewWordBei(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/submitReviewWordBei', params)
      return res
    },
    // 复习练 拿题
    async getReviewWordPingXieShiti(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/getReviewWordPingXieShiti', params)
      return res
    },
    // 复习练 单词提交
    async submitReviewWordLianDanci(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/submitReviewWordLianDanci', params)
      return res
    },
    // 复习练 总提交
    async submitReviewWordLianAll(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/submitReviewWordLianAll', params)
      return res
    },
    // 查询词书计划
    async getLastOneWordLibraryList(context, params) {
      const res = await get('/Study/aiZiZhuWord/getLastOneWordLibraryList', params)
      return res
    },
    // 单词测评词书列表
    async getCiHuiLiangKindList(context, params) {
      const res = await get('/Study/aiZiZhuWord/getCiHuiLiangKindList', params)
      return res
    },
    // 单词测评拿题接口
    async getCiHuiLiangQuestions(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/getCiHuiLiangQuestions', params)
      return res
    },
    // 单词测评提交接口
    async submitCiHuiLiangWord(context, params) {
      const res = await postJson('/Study/aiZiZhuWord/submitCiHuiLiangWord', params)
      return res
    },
    // 单词测评报告列表
    async getCiHuiLiangReportsByStu(context, params) {
      const res = await get('/Study/aiZiZhuWord/getCiHuiLiangReportsByStu', params)
      return res
    },
    // 单词测评报告详情评估判定
    async getCiHuiLiangPingGu(context, params) {
      const res = await get('/Study/aiZiZhuWord/getCiHuiLiangPingGu', params)
      return res
    },
    // 单词测评报告详情
    async getCiHuiLiangPoint(context, params) {
      const res = await get('/Study/aiZiZhuWord/getCiHuiLiangPoint', params)
      return res
    }
    
  }
}
