
import { postJson , get, post} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取学生章节信息
    async getStudentCategoryInfo(context, params) {
      const res = await get('/Study/aiXueLian/getStudentCategoryInfoByPc', params)
      return res
    },
    // 获取学生章节信息带补漏学
    async getStudentCategoryInfoNew(context, params) {
      const res = await get('/Study/aiRelated/getStudentCategoryInfoNew', params)
      return res
    },
    // 获取补漏学列表
    async getRelatedUnitByUser(context, params) {
      const res = await get('/Study/aiRelated/getRelatedUnitByUser', params)
      return res
    },
    // Ai学练-测拿题
    async getAiXueLianQuestions(context, params) {
      // const res = await postJson('/Study/aiEnglish/getAiEnglishQuestions', params)//听力题
      const res = await postJson('/Study/aiXueLian/getAiXueLianQuestions', params)
      // const res = await postJson('/Study/aiXueLian/getAiXueLianQuestionsVersion2', params)
      return res
    },
    // 拿上一次保存的题
    async getSaveGuidShitiByGuid(context, params) {
      const res = await post('/Study/aiXueLian/getSaveGuidShitiByGuid', params)
      return res
    },
    // Ai学练-测提交答案
    async submitAiXueLianCe(context, params) {
      const res = await postJson('/Study/aiXueLian/submitAiXueLianCe', params)
      return res
    },
    // Ai学练-测保存进度提交
    async submitAiXueLianCeSave(context, params) {
      const res = await postJson('/Study/aiXueLian/submitAiXueLianCeSave', params)
      return res
    },
    // 添加学生章节信息
    async insertStudentCategory(context, params) {
      const res = await get('/Study/aiXueLian/insertStudentCategory', params)
      return res
    },
    // 查看解析
    async getPracticeShitiAndTimeByGuid(context, params) {
      const res = await get('/Study/xkwService/getPracticeShitiAndTimeByGuid', params)
      return res
    },
    // guid拿知识点
    async getBadKpointByGuid(context, params) {
      const res = await get('/Study/aiXueLian/getBadKpointByGuid', params)
      return res
    },
    // guid拿知识点和视频
    async getVideoUrlByKpoinrId(context, params) {
      const res = await get('/Study/kpoint/getVideoUrlByKpoinrId', params)
      return res
    },
    // guid拿知识点和视频/云点播
    async getVideoUrlByKpoinrIdYunDianBo(context, params) {
      const res = await get('/Study/kpoint/getVideoUrlByKpoinrIdYunDianBo', params)
      return res
    },
    // 知识点拿视频
    async getVideoUrlBykid(context, params) {
      const res = await get('/Study/kpoint/getVideoUrlBykid', params)
      return res
    },
    // 知识点拿视频云点播
    async getVideoUrlBykidYunDainBo(context, params) {
      const res = await get('/Study/kpoint/getVideoUrlBykidYunDainBo', params)
      return res
    },
    // 同步预习 , 根据video_id 获取 video_url
    async getGlVideoUrlByVideoId(context, params) {
      const res = await get('/Study/tongbuyuxi/getGlVideoUrlByVideoId', params)
      return res
    },
    // 给老师推送消息
    async publish(context, params) {
      const res = await post('/Study/zoAction/teacher/message/publish', params)
      return res
    },
    // 改变在线状态
    async update(context, params) {
      const res = await post('/Study/zoAction/student/online/status/update', params)
      return res
    },
    // 判断是否有用户保存进度
    async getIsSaveByUserNew(context, params) {
      const res = await get('/Study/aiXueLian/getIsSaveByUserNew', params)
      return res
    },
    // 判断用户有没有可以保存的知识点
    async getIsHaveBadKpoint(context, params) {
      const res = await get('/Study/aiXueLian/getIsHaveBadKpoint', params)
      return res
    },
    // 保存用户进度
    async saveBadKpoint(context, params) {
      const res = await get('/Study/aiXueLian/saveBadKpoint', params)
      return res
    },
    // 根据出版社查询测学练状态/Study/xkwService/getUnitStateByVersionId
    async getUnitStateByVersionId(context, params) {
      const res = await get('/Study/xkwService/getUnitStateByVersionId', params)
      return res
    },
    // 获取学生token
    async getTokenByStudentId(context, params) {
      const res = await get('/Study/aiXueLian/login/getTokenByStudentId', params)
      return res
    },
    // 验证学生Token
    async validationTokenByStudentId(context, params) {
      const res = await get('/Study/aiXueLian/login/validationTokenByStudentId', params)
      return res
    },
    // 记录用户看视频时间
    async addStudentVideoTime(context, params) {
      const res = await get('/Study/aiXueLian/addStudentVideoTimeByKpoint', params)
      // const res = await get('/Study/aiXueLian/addStudentVideoTime', params)
      return res
    }
  }
}
