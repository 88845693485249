
import { get } from '@/utils/request'
export default {
    namespaced: true,
    state: {
    },
    getters: {

    },
    mutations: {

    },
    actions: {
        // 获取书本selectUserBookBySubject
        async getctbbook(context, params) {
            const res = await get('/Study/xkwService/selectUserBookBySubjectH5', params)
            return res
        },
        // 获取报告列表
        async getSummaryDateByCategoryId(context, params) {
            const res = await get('/Study/aiXueLian/summary/getSummaryDateByCategoryId', params)
            return res
        },
        // 获取章节内容
        async getAiZxsCategoryByBookId(context, params) {
            const res = await get('/Study/xkwService/getAiZxsCategoryByBookId', params)
            return res
        },
        // 出版社查询是否用章节显示
        async getUnitStateByVersionId(context, params) {
            const res = await get('/Study/xkwService/getUnitStateByVersionId', params)
            return res
        },

    }
}

