import router from './router'
import session from './utils/session'
import {  Message } from 'element-ui'
const whiteList = [
  '/',
  '/myInformation/myInfo',
  '/personalityStudy/dayReport',
  '/personalityStudy/reportPage',
  '/evaluationReport/reportSum',
  '/evaluationReport/reportDay',
  '/englishItem/reportDetail',
  '/englishItem/enPlanDetail',
  '/englishItem/wordAssReport',
  '/promoteMain/promoteReport',
  '/promoteMain/promoteReportPro',
  '/promoteMain/selfReport',
  '/promoteMain/truePaperReport',
  '/promoteMain/paperReport',
  '/promoteMain/wrongReport',
  '/homeWork/pointsReport',
  '/homeWork/kpointReport',
  '/homeWork/unitReport',
  '/homeWork/problemReport',
  '/whiteList/dailySummaryReport',
  '/whiteList/videoPage',
  '/workTool/plandReport',
  '/workTool/planLearningReport',
  '/workTool/analysisDayReport',
  '/myTask/listeningReport',
  '/myTask/specialReport',
  '/myTask/bdcReport',
  '/englishItem/listenReportDe',
  '/myTask/previewReport',
  '/myTask/handReport',
  '/synchronousExercises/synchronousReport'
]

router.beforeEach((to, from, next) => {
  if(whiteList.indexOf(to.path) !== -1){
    next()
  }
  if (session.get('currentUserInfo')) {
    var nowTime = new Date().getTime()
    var EndTime  = new Date(session.get('currentUserInfo').endTime.replace(/\-/g, '/'));
    if (nowTime <= EndTime ) {
      next()
    }else if(whiteList.indexOf(to.path) !== -1){  
      next()
    }else {
    // 清除本地存储
      session.clear()
      location.reload();
      next(`/?redirect=${to.path}`)
      Message.error('登录信息失效，请重新登录')
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/?redirect=${to.path}`)
      Message.error('登录信息失效，请重新登')
    }
  }
})
