import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue')
  },
  // 开发中
  {
    path: '/development',
    name: 'Development',
    component: () => import('@/views/development/index.vue')
  },
  // 我的信息
  {
    path: '/myInformation',
    name: 'MyInformation',
    component: () => import('@/views/myInformation/index.vue'),
    children: [
      {
        path: 'myInfo',
        name: 'MyInfo',
        component: () => import('@/views/myInformation/myInfo/index.vue')
      }
    ]
  },
  // 天天练
  {
    path: '/synchronousExercises',
    name: 'SynchronousExercises',
    component: () => import('@/views/synchronousExercises/index.vue'),
    children: [
      {
        path: 'exercisesIndex',
        name: 'ExercisesIndex',
        component: () => import('@/views/synchronousExercises/exercisesIndex/index.vue')
      },
      {
        path: 'exercTopic',
        name: 'ExercTopic',
        component: () => import('@/views/synchronousExercises/exercisesIndex/exercTopic.vue')
      },
      {
        path: 'exercLearn',
        name: 'ExercLearn',
        component: () => import('@/views/synchronousExercises/exercisesIndex/exercLearn.vue')
      },
      {
        path: 'exercPracti',
        name: 'ExercPracti',
        component: () => import('@/views/synchronousExercises/exercisesIndex/exercPracti.vue')
      },
      {
        path: 'synchronousReport',
        name: 'SynchronousReport',
        component: () => import('@/views/synchronousExercises/synchronousReport/index.vue')
      }
    ]
  },
  // 错题本
  {
    path: '/problemMain',
    name: 'ProblemMain',
    component: () => import('@/views/problemMain/index.vue'),
    children: [
      {
        path: 'problemIndex',
        name: 'ProblemIndex',
        component: () => import('@/views/problemMain/problemIndex/index.vue')
      },
      {
        path: 'problemCut',
        name: 'ProblemCut',
        component: () => import('@/views/problemMain/problemCut/index.vue')
      },
      {
        path: 'problemThree',
        name: 'ProblemThree',
        component: () => import('@/views/problemMain/problemThree/index.vue')
      }
    ]
  },
  // 测评报告
  {
    path: '/evaluationReport',
    name: 'EvaluationReport',
    component: () => import('@/views/evaluationReport/index.vue'),
    children: [
      {
        path: 'reportSum',
        name: 'ReportSum',
        component: () => import('@/views/evaluationReport/reportSum/index.vue')
      },
      {
        path: 'reportDay',
        name: 'ReportDay',
        component: () => import('@/views/evaluationReport/reportSum/reportDay.vue'),
        meta: { title: '每日总结报告' }
      }
    ]
  },
  // 智能测评
  {
    path: '/aiEvaluation',
    name: 'AiEvaluation',
    component: () => import('@/views/aiEvaluation/index.vue'),
    children: [
      {
        path: 'assessList',
        name: 'AssessList',
        component: () => import('@/views/aiEvaluation/assessList/index.vue')
      },
      {
        path: 'testAssess',
        name: 'TestAssess',
        component: () => import('@/views/aiEvaluation/testAssess/index.vue')
      },
      {
        path: 'unitAssess',
        name: 'UnitAssess',
        component: () => import('@/views/aiEvaluation/unitAssess/index.vue')
      },
      {
        path: 'midAssess',
        name: 'MidAssess',
        component: () => import('@/views/aiEvaluation/midAssess/index.vue')
      },
      {
        path: 'finalAssess',
        name: 'FinalAssess',
        component: () => import('@/views/aiEvaluation/finalAssess/index.vue')
      },
      {
        path: 'selfAssess',
        name: 'SelfAssess',
        component: () => import('@/views/aiEvaluation/selfAssess/index.vue')
      },
      {
        path: 'selfTopic',
        name: 'SelfTopic',
        component: () => import('@/views/aiEvaluation/selfAssess/selfTopic.vue')
      }
    ]
  },
  // 提升进阶
  {
    path: '/promoteMain',
    name: 'PromoteMain',
    component: () => import('@/views/promoteMain/index.vue'),
    children: [
      {
        path: 'promoteIndex',
        name: 'PromoteIndex',
        component: () => import('@/views/promoteMain/promoteIndex/index.vue')
      },
      {
        path: 'promoteTopic',
        name: 'PromoteTopic',
        component: () => import('@/views/promoteMain/promoteTopic/index.vue')
      },
      {
        path: 'promoteLearn',
        name: 'PromoteLearn',
        component: () => import('@/views/promoteMain/promoteTopic/promoteLearn.vue')
      },
      {
        path: 'promotePracti',
        name: 'PromotePracti',
        component: () => import('@/views/promoteMain/promoteTopic/promotePracti.vue')
      },
      {
        path: 'promoteReport',
        name: 'PromoteReport',
        component: () => import('@/views/promoteMain/promoteReport/index.vue'),
        meta: { title: '智能测评报告' }
      },
      {
        path: 'promoteReportPro',
        name: 'PromoteReportPro',
        component: () => import('@/views/promoteMain/promoteReport/indexPro.vue'),
        meta: { title: '智能测评报告' }
      },
      {
        path: 'selfReport',
        name: 'SelfReport',
        component: () => import('@/views/promoteMain/promoteReport/selfReport.vue'),
        meta: { title: '自主测评报告' }
      },
      {
        path: 'truePaperReport',
        name: 'TruePaperReport',
        component: () => import('@/views/promoteMain/truePaperReport/index.vue'),
        meta: { title: '考前冲刺报告' }
      },
      {
        path: 'paperLearn',
        name: 'PaperLearn',
        component: () => import('@/views/promoteMain/promoteTopic/paperLearn.vue')
      },
      {
        path: 'paperPracti',
        name: 'PaperPracti',
        component: () => import('@/views/promoteMain/promoteTopic/paperPracti.vue')
      },
      {
        path: 'paperReport',
        name: 'PaperReport',
        component: () => import('@/views/promoteMain/paperReport/index.vue'),
        meta: { title: 'Ai试卷报告' }
      },
      {
        path: 'wrongReport',
        name: 'WrongReport',
        component: () => import('@/views/promoteMain/paperReport/wrongReport.vue'),
        meta: { title: 'Ai作业错题报告' }
      }
    ]
  },
  // 英语专项
  {
    path: '/englishItem',
    name: 'EnglishItem',
    component: () => import('@/views/englishItem/index.vue'),
    children: [
      {
        path: 'englishIndex',
        name: 'EnglishIndex',
        component: () => import('@/views/englishItem/englishIndex/index.vue')
      },
      {
        path: 'wordList',//单词学习
        name: 'WordList',
        component: () => import('@/views/englishItem/wordList/index.vue')
      },
      {
        path: 'wordTopic',//背单词-背
        name: 'WordTopic',
        component: () => import('@/views/englishItem/wordList/wordTopic.vue')
      },
      {
        path: 'learnWord',//背单词-学
        name: 'LearnWord',
        component: () => import('@/views/englishItem/wordList/learnWord.vue')
      },
      {
        path: 'practiWord',//背单词-练
        name: 'PractiWord',
        component: () => import('@/views/englishItem/wordList/practiWord.vue')
      },
      {
        path: 'testWord',//背单词-测
        name: 'TestWord',
        component: () => import('@/views/englishItem/wordList/testWord.vue')
      },
      {
        path: 'newWords',//生词本
        name: 'NewWords',
        component: () => import('@/views/englishItem/newWords/index.vue')
      },
      {
        path: 'enGraph',//英语图谱
        name: 'EnGraph',
        component: () => import('@/views/englishItem/enGraph/index.vue')
      },
      {
        path: 'enZiran',//自然拼读
        name: 'EnZiran',
        component: () => import('@/views/englishItem/enZiran/index.vue')
      },
      {
        path: 'enZiLi',//自然拼读
        name: 'EnZiLi',
        component: () => import('@/views/englishItem/enZiran/enZiLi.vue')
      },
      {
        path: 'phonetics',//音标学习
        name: 'Phonetics',
        component: () => import('@/views/englishItem/phonetics/index.vue')
      },
      {
        path: 'enReport',//单词报告列表
        name: 'EnReport',
        component: () => import('@/views/englishItem/enReport/index.vue')
      },
      {
        path: 'enPlanReport',//单词报告列表2
        name: 'EnPlanReport',
        component: () => import('@/views/englishItem/enReport/enPlanReport.vue')
      },
      {
        path: 'enPlanDetail',//计划单词报告详情
        name: 'EnPlanDetail',
        component: () => import('@/views/englishItem/enReport/enPlanDetail.vue')
      },
      {
        path: 'reportDetail',//单词报告列表
        name: 'ReportDetail',
        component: () => import('@/views/englishItem/enReport/reportDetail.vue')
      },
      {
        path: 'listeningTopic',//听力练习
        name: 'ListeningTopic',
        component: () => import('@/views/englishItem/listeningTopic/index.vue')
      },
      {
        path: 'listenReport',//听力报告列表
        name: 'ListenReport',
        component: () => import('@/views/englishItem/listeningTopic/listenReport.vue')
      },
      {
        path: 'listenReportDe',//听力报告
        name: 'ListenReportDe',
        component: () => import('@/views/englishItem/listeningTopic/listenReportDe.vue')
      },
      {
        path: 'clozeTopic',//完型填空
        name: 'ClozeTopic',
        component: () => import('@/views/englishItem/clozeTopic/index.vue')
      },
      {
        path: 'reviewTest',//复习测
        name: 'ReviewTest',
        component: () => import('@/views/englishItem/reviewWord/reviewTest.vue')
      },
      {
        path: 'reviewLearn',//复习学
        name: 'ReviewLearn',
        component: () => import('@/views/englishItem/reviewWord/reviewLearn.vue')
      },
      {
        path: 'reviewPracti',//复习练
        name: 'ReviewPracti',
        component: () => import('@/views/englishItem/reviewWord/reviewPracti.vue')
      },
      {
        path: 'wordAss',//单词测评
        name: 'WordAss',
        component: () => import('@/views/englishItem/wordAss/index.vue')
      },
      {
        path: 'wordAssTopic',//单词测评做题界面
        name: 'WordAssTopic',
        component: () => import('@/views/englishItem/wordAss/wordAssTopic.vue')
      },
      {
        path: 'wordAssReLIst',//报告列表
        name: 'WordAssReLIst',
        component: () => import('@/views/englishItem/wordAss/wordAssReLIst.vue')
      },
      {
        path: 'wordAssReport',//单词报告详情
        name: 'WordAssReport',
        component: () => import('@/views/englishItem/wordAss/wordAssReport.vue')
      }
    ]
  },
  // 个性化图谱
  {
    path: '/personalityStudy',
    name: 'PersonalityStudy',
    component: () => import('@/views/personalityStudy/index.vue'),
    children: [
      {
        path: 'personalityMain',
        name: 'PersonalityMain',
        component: () => import('@/views/personalityStudy/personalityMain/index.vue')
      },
      {
        path: 'personalityIndex',
        name: 'PersonalityIndex',
        component: () => import('@/views/personalityStudy/personalityIndex/index.vue')
      },
      {
        path: 'testTopic',//测
        name: 'TestTopic',
        component: () => import('@/views/personalityStudy/testLearnAll/testTopic.vue')
      },
      {
        path: 'learnTopic',//学
        name: 'LearnTopic',
        component: () => import('@/views/personalityStudy/testLearnAll/learnTopic.vue')
      },
      {
        path: 'practiTopic',//练
        name: 'PractiTopic',
        component: () => import('@/views/personalityStudy/testLearnAll/practiTopic.vue')
      },
      {
        path: 'testTopicBu',//补漏测
        name: 'TestTopicBu',
        component: () => import('@/views/personalityStudy/testLearnAll/testTopicBu.vue')
      },
      {
        path: 'learnTopicBu',//补漏学
        name: 'LearnTopicBu',
        component: () => import('@/views/personalityStudy/testLearnAll/learnTopicBu.vue')
      },
      {
        path: 'practiTopicBu',//补漏练
        name: 'PractiTopicBu',
        component: () => import('@/views/personalityStudy/testLearnAll/practiTopicBu.vue')
      },
      {
        path: 'knowledgeGraph',//图谱
        name: 'KnowledgeGraph',
        component: () => import('@/views/personalityStudy/knowledgeGraph/index.vue')
      },
      {
        path: 'topicIndex',
        name: 'TopicIndex',
        component: () => import('@/views/personalityStudy/topicIndex/index.vue')
      },
      {
        path: 'dayReport',
        name: 'DayReport',
        component: () => import('@/views/personalityStudy/dayReport/index.vue')
      },
      {
        path: 'reportPage',
        name: 'ReportPage',
        component: () => import('@/views/personalityStudy/dayReport/reportPage.vue'),
        meta: { title: 'AI自习室-每日报告' }
      },
      {
        path: 'patchWork',
        name: 'PatchWork',
        component: () => import('@/views/personalityStudy/patchWork/index.vue'),
        meta: { title: '补漏学' }
      }
    ]
  },
  // 同步名师
  {
    path: '/teacherVideo',
    name: 'TeacherVideo',
    component: () => import('@/views/teacherVideo/index.vue'),
    children: [
      {
        path: 'famousTeacher',
        name: 'FamousTeacher',
        component: () => import('@/views/teacherVideo/famousTeacher/index.vue')
      }
    ]
  },
  // 课后作业
  {
    path: '/homeWork',
    name: 'HomeWork',
    component: () => import('@/views/homeWork/index.vue'),
    children: [
      {
        path: 'homeWorkIndex',
        name: 'HomeWorkIndex',
        component: () => import('@/views/homeWork/homeWorkIndex/index.vue')
      },
      {
        path: 'unitTest',
        name: 'UnitTest',
        component: () => import('@/views/homeWork/unitTest/index.vue')
      },
      {
        path: 'unitTopic',
        name: 'UnitTopic',
        component: () => import('@/views/homeWork/unitTest/unitTopic.vue')
      },
      {
        path: 'problemTest',
        name: 'ProblemTest',
        component: () => import('@/views/homeWork/problemTest/index.vue')
      },
      {
        path: 'problemTopic',
        name: 'ProblemTopic',
        component: () => import('@/views/homeWork/problemTest/problemTopic.vue')
      },
      {
        path: 'strengthenTest',
        name: 'StrengthenTest',
        component: () => import('@/views/homeWork/strengthenTest/index.vue')
      },
      {
        path: 'strengTopic',
        name: 'StrengTopic',
        component: () => import('@/views/homeWork/strengthenTest/strengTopic.vue')
      },
      {
        path: 'strengLearn',
        name: 'StrengLearn',
        component: () => import('@/views/homeWork/strengthenTest/strengLearn.vue')
      },
      {
        path: 'strengPracti',
        name: 'StrengPracti',
        component: () => import('@/views/homeWork/strengthenTest/strengPracti.vue')
      },
      {
        path: 'strengLearnPaper',
        name: 'StrengLearnPaper',
        component: () => import('@/views/homeWork/strengthenTest/strengLearnPaper.vue')
      },
      {
        path: 'strengPractiPaper',
        name: 'StrengPractiPaper',
        component: () => import('@/views/homeWork/strengthenTest/strengPractiPaper.vue')
      },
      {
        path: 'pointsTest',
        name: 'PointsTest',
        component: () => import('@/views/homeWork/pointsTest/index.vue')
      },
      {
        path: 'pointsList',
        name: 'PointsList',
        component: () => import('@/views/homeWork/pointsTest/pointsList.vue')
      },
      {
        path: 'pointsLearn',
        name: 'PointsLearn',
        component: () => import('@/views/homeWork/pointsTest/pointsLearn.vue')
      },
      {
        path: 'pointsPracti',
        name: 'PointsPracti',
        component: () => import('@/views/homeWork/pointsTest/pointsPracti.vue')
      },
      {
        path: 'pointsReport',
        name: 'PointsReport',
        component: () => import('@/views/homeWork/pointsTest/pointsReport.vue'),
        meta: { title: '考点强化报告' }
      },
      {
        path: 'kpointReport',
        name: 'KpointReport',
        component: () => import('@/views/homeWork/strengthenTest/kpointReport.vue'),
        meta: { title: '知识点强化报告' }
      },
      {
        path: 'unitReport',
        name: 'UnitReport',
        component: () => import('@/views/homeWork/unitTest/unitReport.vue')
      },
      {
        path: 'problemReport',
        name: 'ProblemReport',
        component: () => import('@/views/homeWork/problemTest/problemReport.vue')
      }
    ]
  },
  // 我的任务
  {
    path: '/myTask',
    name: 'MyTask',
    component: () => import('@/views/myTask/index.vue'),
    children: [
      {
        path: 'taskIndex',
        name: 'TaskIndex',
        component: () => import('@/views/myTask/taskIndex/index.vue')
      },
      {
        path: 'cxlTask',
        name: 'CxlTask',
        component: () => import('@/views/myTask/cxlTask/index.vue')
      },
      {
        path: 'cepTask',
        name: 'CepTask',
        component: () => import('@/views/myTask/cepTask/index.vue')
      },
      {
        path: 'cepTopic',
        name: 'CepTopic',
        component: () => import('@/views/myTask/cepTask/cepTopic.vue')
      },
      {
        path: 'listeningReport',
        name: 'ListeningReport',
        component: () => import('@/views/myTask/listeningReport/index.vue')
      },
      {
        path: 'specialIndex',
        name: 'SpecialIndex',
        component: () => import('@/views/myTask/specialTask/index.vue')
      },
      {
        path: 'specialList',
        name: 'SpecialList',
        component: () => import('@/views/myTask/specialTask/specialList.vue')
      },
      {
        path: 'specialLearn',
        name: 'SpecialLearn',
        component: () => import('@/views/myTask/specialTask/specialLearn.vue')
      },
      {
        path: 'specialPracti',
        name: 'SpecialPracti',
        component: () => import('@/views/myTask/specialTask/specialPracti.vue')
      },
      {
        path: 'specialReport',
        name: 'SpecialReport',
        component: () => import('@/views/myTask/specialTask/specialReport.vue')
      },
      {
        path: 'enBdcTask',
        name: 'EnBdcTask',
        component: () => import('@/views/myTask/enBdcTask/index.vue')
      },
      {
        path: 'bdcTest',
        name: 'BdcTest',
        component: () => import('@/views/myTask/enBdcTask/bdcTest.vue')
      },
      {
        path: 'bdcLearn',
        name: 'BdcLearn',
        component: () => import('@/views/myTask/enBdcTask/bdcLearn.vue')
      },
      {
        path: 'bdcPracti',
        name: 'BdcPracti',
        component: () => import('@/views/myTask/enBdcTask/bdcPracti.vue')
      },
      {
        path: 'bdcReport',
        name: 'BdcReport',
        component: () => import('@/views/myTask/enBdcTask/bdcReport.vue')
      },
      {
        path: 'previewTest',//预习任务
        name: 'PreviewTest',
        component: () => import('@/views/myTask/previewTest/index.vue')
      },
      {
        path: 'previewList',
        name: 'PreviewList',
        component: () => import('@/views/myTask/previewTest/previewList.vue')
      },
      {
        path: 'previewLearn',
        name: 'PreviewLearn',
        component: () => import('@/views/myTask/previewTest/previewLearn.vue')
      },
      {
        path: 'previewPracti',
        name: 'PreviewPracti',
        component: () => import('@/views/myTask/previewTest/previewPracti.vue')
      },
      {
        path: 'previewReport',
        name: 'PreviewReport',
        component: () => import('@/views/myTask/previewTest/previewReport.vue'),
        meta: { title: '同步预习报告' }
      },
      {
        path: 'handReport',
        name: 'HandReport',
        component: () => import('@/views/myTask/handTask/handReport.vue'),
        meta: { title: '智能讲义报告' }
      },
      {
        path: 'layeredTask',//分层测评任务
        name: 'LayeredTask',
        component: () => import('@/views/myTask/layeredTask/index.vue')
      },
      {
        path: 'layeredTopic',//分层测评做题
        name: 'LayeredTopic',
        component: () => import('@/views/myTask/layeredTask/layeredTopic.vue')
      }
    ]
  },
  // Ai作业工具
  {
    path: '/workTool',
    name: 'WorkTool',
    component: () => import('@/views/workTool/index.vue'),
    children: [
      {
        path: 'toolList',
        name: 'ToolList',
        component: () => import('@/views/workTool/toolList/index.vue')
      },
      {
        path: 'plandReport',
        name: 'PlandReport',
        component: () => import('@/views/workTool/plandReport/index.vue'),
        meta: { title: 'Ai作业规划报告' }
      },
      {
        path: 'planLearningReport',
        name: 'planLearningReport',
        component: () => import('@/views/workTool/plandReport/learningPlan.vue'),
        meta: { title: 'Ai学习规划报告' }
      },
      {
        path: 'analysisDayReport',
        name: 'AnalysisDayReport',
        component: () => import('@/views/workTool/plandReport/analysisDayReport.vue'),
        meta: { title: '每日分析报告' }
      }
    ]
  },
  {
    path: '/whiteList',
    name: 'WhiteList',
    component: () => import('@/views/whiteList/index.vue'),
    children: [
      {
        path: 'dailySummaryReport',
        name: 'DailySummaryReport',
        component: () => import('@/views/whiteList/dailySummaryReport/index.vue')
      },
      {
        path: 'videoPage',
        name: 'VideoPage',
        component: () => import('@/views/whiteList/videoIndex/index.vue')
      }
    ]
  },
  // 任务积分
  {
    path: '/taskIntegral',
    name: 'TaskIntegral',
    component: () => import('@/views/taskIntegral/index.vue'),
    children: [
      {
        path: 'taskIntegralRanking',
        name: 'TaskIntegralRanking',
        component: () => import('@/views/taskIntegral/taskIntegralRanking/index.vue')
      },
      {
        path: 'taskIntegralDate',
        name: 'TaskIntegralDate',
        component: () => import('@/views/taskIntegral/taskIntegralDate/index.vue')
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
