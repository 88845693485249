
import { get, postJson ,post} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取章节内容
    async getzhangjnr(context, params) {
      const res = await get('/Study/xkwService/getAiZxsCategoryByBookId', params)
      return res
    },
    // 天天练试题
    async getTopicList(context, params) {
      const res = await postJson('/Study/xkwService/getPracticeQuestions', params)
      return res
    },
    // 天天练答案
    async getdaanList(context, params) {
      const res = await postJson('/Study/xkwService/submitPractice', params)
      return res
    },
    // 智能测评提交带时间
    async submitPracticeAddTime(context, params) {
      const res = await postJson('/Study/xkwService/submitPracticeAddTime', params)
      return res
    },
    // 获取单个题目解析
    async getParse(context, params) {
      const res = await get('/Study/xkwService/getPracticeShitiByGuid', params)
      return res
    },
    // 获取题目解析带时间
    async getPracticeShitiAndTimeByGuid(context, params) {
      const res = await get('/Study/xkwService/getPracticeShitiAndTimeByGuid', params)
      return res
    },
    // 通过学科网书本ID和章ID获取视频
    async getVideoli(context, params) {
      const res = await get('/Study/glVideo/books/video/get', params)
      return res
    },
    // 获取名师视频url
    async getGlVideoUrl(context, params) {
      const res = await get('/Study/glVideo/getGlVideoUrl', params)
      return res
    },
    // 注册手机号
    async phoneRegister(context, params) {
      const res = await post('/Study/glVideo/gelin/register', params)
      return res
    },
    // 章节获取知识点视频列表
    async getKpointVideoListByUnit(context, params) {
      const res = await post('/Study/kpoint/getKpointVideoListByUnit', params)
      return res
    },
    // 根据章节获取知识点(带是否有视频) 2024-04-25 小学语文用同步视频
    async getKpointVideoListByUnitNew(context, params) {
      const res = await post('/Study/kpoint/getKpointVideoListByUnitNew', params)
      return res
    },
    // 获取同步视频列表240720
    async getTongBuVideoListByUnit(context, params) {
      const res = await post('/Study/tongbuyuxi/getTongBuVideoListByUnit', params)
      return res
    },
    // 自主预习生成任务240802
    async setStudentTongbuYuXiHomework(context, params) {
      const res = await get('/Study/tongbuyuxi/setStudentTongbuYuXiHomework', params)
      return res
    },
    // 学生自主预习 -- (学生端)获取报告列表
    async getStudentTongbuReportList(context, params) {
      const res = await get('/Study/tongbuyuxi/getStudentTongbuReportList', params)
      return res
    }
  }
}

