
import { post, postJson } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    
    // 根据知识点拿视频id
    async getKnowledgeVideo(context, params) {
      const res = await post('/Study/kpoint/getKnowledgeVideo', params)
      return res
    },
    // 根据视频id拿URL
    async getVideoUrlByVideoId(context, params) {
      const res = await post('/Study/kpoint/getVideoUrlByVideoId', params)
      return res
    },
    // 举一反三拿题
    async getQListByGuidShitiId(context, params) {
      const res = await postJson('/Study/wrongQuestion/getQListByGuidShitiId', params)
      return res
    },
   

  }
}

