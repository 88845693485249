
import { get } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取报告列表
    async getReportDateByCategoryId(context, params) {
      const res = await get('/Study/aiXueLian/getReportDateByCategoryId', params)
      return res
    },
    // 获取报告总评
    async getGeneralComment(context, params) {
      const res = await get('/Study/aiXueLian/getGeneralComment', params)
      return res
    },
    // 个人亮点
    async getBrightenedDot(context, params) {
      const res = await get('/Study/aiXueLian/getBrightenedDot', params)
      return res
    },
    // 章节学习进度
    async getRateOfLearning(context, params) {
      const res = await get('/Study/aiXueLian/getRateOfLearning', params)
      return res
    },
    // 基础数据
    async getBasicData(context, params) {
      const res = await get('/Study/aiXueLian/getBasicData', params)
      return res
    },
    // 薄弱知识点学习评价
    async getStudyAppraise(context, params) {
      const res = await get('/Study/aiXueLian/getStudyAppraise', params)
      return res
    },
    // 知识点掌握详情
    async getStudentReportByDate(context, params) {
      const res = await get('/Study/aiXueLian/getStudentReportByDate', params)
      return res
    },
    // 知识点时长
    async getKopintUseTime(context, params) {
      const res = await get('/Study/aiXueLian/getKopintUseTime', params)
      return res
    },
    // 答题用时分析
    async getShitiUseTime(context, params) {
      const res = await get('/Study/aiXueLian/getShitiUseTime', params)
      return res
    },
    // 获取当日答题详情
    async getHistoryShitiByStudentNew(context, params) {
      const res = await get('/Study/teacher/getHistoryShitiByStudentNew', params)
      return res
    }
  }
}
