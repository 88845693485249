// 封装本地存储session
const $session = {
  get: key => {
    return JSON.parse(window.sessionStorage.getItem(key))
  },
  set: (key, value) => {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  remove: key => {
    window.sessionStorage.removeItem(key)
  },
  clear: () => {
    window.sessionStorage.clear()
  }
}
export default $session
