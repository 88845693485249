
import { get } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取报告列表
    async getSummaryDateByCategoryId(context, params) {
      const res = await get('/Study/aiXueLian/summary/getSummaryDateByCategoryId', params)
      return res
    },
    // 获取报告总评
    async getGeneralCommentSummary(context, params) {
      const res = await get('/Study/aiXueLian/summary/getGeneralCommentSummary', params)
      return res
    },
    // 个人亮点
    async getBrightenedDotSummary(context, params) {
      const res = await get('/Study/aiXueLian/summary/getBrightenedDotSummary', params)
      return res
    },
    // 章节学习进度
    async getRateOfLearningSummary(context, params) {
      const res = await get('/Study/aiXueLian/summary/getRateOfLearningSummary', params)
      return res
    },
    // 基础数据
    async getBasicDataSummary(context, params) {
      const res = await get('/Study/aiXueLian/summary/getBasicDataSummary', params)
      return res
    },
    // 薄弱知识点学习评价
    async getStudyAppraiseSummary(context, params) {
      const res = await get('/Study/aiXueLian/summary/getStudyAppraiseSummary', params)
      return res
    },
    // 知识点掌握详情
    async getStudentSummaryByDate(context, params) {
      const res = await get('/Study/aiXueLian/summary/getStudentSummaryByDate', params)
      return res
    },
    // 知识点时长
    async getKopintUseTimeSummary(context, params) {
      const res = await get('/Study/aiXueLian/summary/getKopintUseTimeSummary', params)
      return res
    },
    // 答题用时分析
    async getShitiUseTimeSummary(context, params) {
      const res = await get('/Study/aiXueLian/summary/getShitiUseTimeSummary', params)
      return res
    },
  }
}
