
import { get} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 学生获取规划表
    async getPlanZuoyeByStudent(context, params) {
      const res = await get('/Study/plan/getPlanZuoyeByStudent', params)
      return res
    },
    // 规划表详情
    async getZuoyeByInfoByPlanId(context, params) {
      const res = await get('/Study/plan/getZuoyeByInfoByPlanId', params)
      return res
    },
    // 学习规划表详情
    async getStudyPlanReport(context, params) {
      const res = await get('/Study/cepingReport/getStudyPlanReport', params)
      return res
    },
    // 学习规划表详情9.10
    async getZuoyeSubInfoByPlanId(context, params) {
      const res = await get('/Study/plan/getZuoyeSubInfoByPlanId', params)
      return res
    },
    // 每日分析报告详情
    async getStudyAnalyReport(context, params) {
      const res = await get('/Study/cepingReport/getStudyAnalyReport', params)
      return res
    }
    
  }
}

