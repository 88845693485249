
import { get } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取排行
    async getIntegrationRanking(context, params) {
      const res = await get('/Study/task/getIntegrationRanking', params)
      return res
    },
    // 获取学生所在班级
    async getClassListByStudent(context, params) {
      const res = await get('/Study/task/getClassListByStudent', params)
      return res
    },
    // 积分每月
    async getIntegrationByMonth(context, params) {
      const res = await get('/Study/task/getIntegrationByMonth', params)
      return res
    },
    // 获取学生积分详情
    async getIntegrationInfoByday(context, params) {
      const res = await get('/Study/task/getIntegrationInfoByday', params)
      return res
    }
  }
}

