
import { get, postJson } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取测评记录
    async getTimuGuid(context, params) {
      const res = await get('/Study/tifenjinjie/getTimuGuid', params)
      return res
    },
    // 获取测评试卷记录
    async getShiJuanTimuGuid(context, params) {
      // const res = await get('/Study/xkwCeping/getShiJuanTimuGuid', params)
      const res = await get('/Study/xkwCeping/getCePingShiJuanList', params)
      return res
    },
    // 删除测评记录
    async delectTimuGuidById(context, params) {
      const res = await get('/Study/tifenjinjie/delectTimuGuidById', params)
      return res
    },
    // 提升进阶详情
    async getTimuKpointInfoByGuid(context, params) {
      const res = await get('/Study/tifenjinjie/getTimuKpointInfoByGuid', params)
      return res
    },
    // 提升进阶-强化训练
    async getQListByKpointId(context, params) {
      const res = await postJson('/Study/tifenjinjie/getQListByKpointId', params)
      return res
    },
    // 提升进阶查看解析
    async getShitiInfoByGuidKpointId(context, params) {
      const res = await get('/Study/tifenjinjie/getShitiInfoByGuidKpointId', params)
      return res
    },
    // 记录用户观看强化训练视频时间(带知识点)
    async addCepingVideoTimeByKpoint(context, params) {
      const res = await get('/Study/tifenjinjie/addCepingVideoTimeByKpoint', params)
      return res
    },
    // 提升进阶强化训练提交
    async submitQiangHuaXueLian(context, params) {
      const res = await postJson('/Study/xkwService/submitQiangHuaXueLian', params)
      return res
    },
    // 获取Ai试卷列表
    async getShijuanListByStu(context, params) {
      const res = await get('/Study/analyse/getShijuanListByStu', params)
      return res
    },
    // 获取知识点详情
    async getShijuanKpointInfo(context, params) {
      const res = await get('/Study/analyse/getShijuanKpointInfo', params)
      return res
    },
    // 获取试卷和知识点详情图片
    async getshitiPictureList(context, params) {
      const res = await get('/Study/analyse/getshitiPictureList', params)
      return res
    },
    // Ai试卷分析-强化训练
    async getQListByShiJuanKpointId(context, params) {
      const res = await get('/Study/analyse/getQListByShiJuanKpointId', params)
      return res
    },
    // 自主测评记录
    async getZiZhuTimuGuid(context, params) {
      const res = await get('/Study/zizhuCeping/getZiZhuTimuGuid', params)
      return res
    },
    // 自主测评记录知识点详情
    async getZiZhuTimuKpointInfoByGuid(context, params) {
      const res = await get('/Study/zizhuCeping/getZiZhuTimuKpointInfoByGuid', params)
      return res
    }

  }
}

