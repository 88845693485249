
import { get, postJson} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 查询跳转页面
    async getWordTaskModule(context, params) {
      const res = await get('/Study/taskWord/getWordTaskModule', params)
      return res
    },
    // 根据课后作业ID获取 第一次试题 , 或者保存试题
    async getWordTaskShitiByHomeworkId(context, params) {
      const res = await get('/Study/taskWord/getWordTaskShitiByHomeworkId', params)
      return res
    },
    // 获取练 拼写试题
    async getLianShitiByHomeworkId(context, params) {
      const res = await get('/Study/taskWord/getLianShitiByHomeworkId', params)
      return res
    },
    // 测单次提交
    async submitWordTaskCeDanci(context, params) {
      const res = await postJson('/Study/taskWord/submitWordTaskCeDanci', params)
      return res
    },
    // 测总提交
    async submitWordTaskCe(context, params) {
      const res = await postJson('/Study/taskWord/submitWordTaskCe', params)
      return res
    },
    // 练单次提交
    async submitTaskLianDanci(context, params) {
      const res = await postJson('/Study/taskWord/submitTaskLianDanci', params)
      return res
    },
    // 练总提交
    async submitWorkTaskLian(context, params) {
      const res = await postJson('/Study/taskWord/submitWorkTaskLian', params)
      return res
    },
    // 获取所有单词解析(练)
    async getPingXieShitiInfoByGuid(context, params) {
        const res = await get('/Study/aiWord/getPingXieShitiInfoByGuid', params)
        return res
      },
    // 报告详情
    async getWordTaskReportTaskInfo(context, params) {
        const res = await get('/Study/taskWord/getWordTaskReportTaskInfo', params)
        return res
    },
    // 报告概览
    async getWordTaskReportGaiLian(context, params) {
        const res = await get('/Study/taskWord/getWordTaskReportGaiLian', params)
        return res
    },
    // 获取报告基础数据 任务详情
    async getWordTaskReportData(context, params) {
        const res = await get('/Study/taskWord/getWordTaskReportData', params)
        return res
    },
    // 获取报告用户单词综合详情
    async getWordTaskReportUserWordInfo(context, params) {
        const res = await get('/Study/taskWord/getWordTaskReportUserWordInfo', params)
        return res
    }
    
  }
}

