
import { get } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取报告总评
    async getKaoDianUserInfo(context, params) {
      const res = await get('/Study/kaodian/getKaoDianUserInfo', params)
      return res
    },
    // 知识点掌握详情
    async getKaoDianKpointInfo(context, params) {
      const res = await get('/Study/kaodian/getKaoDianKpointInfo', params)
      return res
    },
    // 所有答题详情
    async getKaoDianShitiByHomeworkId(context, params) {
      const res = await get('/Study/kaodian/getKaoDianShitiByHomeworkId', params)
      return res
    },
    // 知识点答题详情
    async getShitiInfoByGuidKpointId(context, params) {
      const res = await get('/Study/tifenjinjie/getShitiInfoByGuidKpointId', params)
      return res
    },
    // 获取知识点做题和看视频时长
    async getKaoDdianKpointVideoTime(context, params) {
      const res = await get('/Study/kaodian/getKaoDdianKpointVideoTime', params)
      return res
    },
    // 预习报告基础数据
    async getTongBuYuXiReportInfo(context, params) {
      const res = await get('/Study/tongbuyuxi/getTongBuYuXiReportInfo', params)
      return res
    },
    // 考点强化基础数据
    async getKaoDianReportBasicData(context, params) {
      const res = await get('/Study/kaodian/getKaoDianReportBasicData', params)
      return res
    },
    // 知识点强化总评
    async getKpointReportUserInfo(context, params) {
      const res = await get('/Study/kaodian/getKpointReportUserInfo', params)
      return res
    },
    // 知识点强化知识点详情
    async getKpointReportInfo(context, params) {
      const res = await get('/Study/kaodian/getKpointReportInfo', params)
      return res
    },
    // 知识点强化报告 -- 获取知识点做题和看视频时长
    async getKpointReportVideoTime(context, params) {
      const res = await get('/Study/kaodian/getKpointReportVideoTime', params)
      return res
    },
    // 知识点强化报告基础数据
    async getKpointReportBasicData(context, params) {
      const res = await get('/Study/kaodian/getKpointReportBasicData', params)
      return res
    },
    // 根据homework_id获取知识点强化练习后带时间的试题
    async getKpointReportShiti(context, params) {
      const res = await get('/Study/kaodian/getKpointReportShiti', params)
      return res
    }
  }
}
