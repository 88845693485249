
import { postJson } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // Ai学练-练拿题
    async getAiXueLianQList(context, params) {
      const res = await postJson('/Study/aiXueLian/getAiXueLianQList', params)
      return res
    },
    // Ai学练-练拿题带填空题
    async getAiXueLianQListByTK(context, params) {
      const res = await postJson('/Study/aiXueLian/getAiXueLianQListByTK', params)
      // const res = await postJson('/Study/aiXueLian/getAiXLQListByTKNewVersion', params)
      return res
    },
    // Ai学练-练提交答案
    async submitAiXueLianLian(context, params) {
      const res = await postJson('/Study/aiXueLian/submitAiXueLianLian', params)
      return res
    },
    // Ai学练-练提交答案填空题
    async submitAiXueLianLianByTK(context, params) {
      const res = await postJson('/Study/aiXueLian/submitAiXueLianLianByTK', params)
      return res
    },
    // Ai学练-练提交答案填空题分次提交
    async submitAiXueLianLianByCi(context, params) {
      const res = await postJson('/Study/aiXueLian/submitAiXueLianLianByCi', params)
      return res
    }
  }
}
