
import { post } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取测评记录
    async fetchAiCePingList(context, params) {
      const res = await post('/lazyaiscore/h5report/h5getAiCePingListBySubject', params)
      return res
    },
    // 获取测评报告整体掌握情况
    async h5getAiCePingLearnData(context, params) {
      const res = await post('/lazyaiscore/h5report/h5getAiCePingLearnData', params)
      return res
    },
    // 02模块知识点掌握情况
    async h5getAiCePingLearnPercent(context, params) {
      const res = await post('/lazyaiscore/h5report/h5getAiCePingLearnPercent', params)
      return res
    },
    //
    async h5getTimuByGuid(context, params) {
      const res = await post('/lazyaiscore/h5report/h5getTimuByGuid', params)
      return res
    },
    // 每周得分数据图
    async h5getAvgScoreBySumWeek(context, params) {
      const res = await post('/lazyaiscore/h5report/h5getAvgScoreBySumWeek', params)
      return res
    },
    // 测评结果
    async h5getCePingResult(context, params) {
      const res = await post('lazyaiscore/h5report/h5getCePingResult', params)
      return res
    },
    // 打卡记录
    async selectBySnAndSubjectid(context, params) {
      const res = await post('/lazyaiscore/service/selectBySnAndSubjectid', params)
      return res
    },
    // 学情报告
    async h5searchBySnAndSubjectid(context, params) {
      const res = await post('/lazyaiscore/h5report/h5searchBySnAndSubjectid', params)
      return res
    }
  }
}

