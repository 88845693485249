
import { postJson , get, post} from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取听力试题
    async getTingLiQuestions(context, params) {
      const res = await postJson('/Study/aiEnglish/getTingLiQuestions', params)
      return res
    },
    // 获取完形填空题
    async getWanXingTainKongQuestions(context, params) {
      const res = await postJson('/Study/aiEnglish/getWanXingTainKongQuestions', params)
      return res
    },
   // 听力和完型填空提交
   async submitAiEnglish(context, params) {
    const res = await postJson('/Study/aiEnglish/submitAiEnglish', params)
    return res
  },
   // 听力测评列表
   async getTingliCePingList(context, params) {
    const res = await get('/Study/aiEnglish/getTingliCePingList', params)
    return res
  },
   // 听力测评详情
   async getTingLiReportByGuid(context, params) {
    const res = await get('/Study/aiEnglish/getTingLiReportByGuid', params)
    return res
  },
    
  }
}
