
import { get, postJson } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取所有知识点
    async getAsdPicBySnAndBookid(context, params) {
      const res = await get('/Study/kpoint/getKpointAndNumListByUnit', params)
      return res
    },
    // 个性化训练拿题
    async getKpointQList(context, params) {
      // const res = await postJson('/Study/kpoint/getKpointQList', params)
      const res = await postJson('/Study/kpoint/getKpointQListNew', params)
      return res
    }
  }
}
