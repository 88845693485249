import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/style/index.scss'

Vue.config.productionTip = false;

//导入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



// 导入本地存储
import session from './utils/session'
import local from './utils/local'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import './permission'

router.beforeEach((to, from, next) => {
  // console.log(to.meta,'to')
  if (to.meta.title) {
    document.title = to.meta.title
  }else{
    document.title = 'AI自习室'
  }
  next()
})
if (process.env.NODE_ENV === 'production') {
  // 正式环境清除所有console.log
  if (window) {
    window.console.log = function() {}
  }
}
//使用ElementUI
Vue.use(ElementUI);
/* import print from '@/utils/print'
Vue.use(print) */

Vue.prototype.$session = session
Vue.prototype.$local = local
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
