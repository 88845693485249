
import { get } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取报告总评
    async getCepingUserInfo(context, params) {
      const res = await get('/Study/cepingReport/getCepingUserInfo', params)
      return res
    },
    // 个人亮点
    async getBrightenedDot(context, params) {
      const res = await get('/Study/aiXueLian/getBrightenedDot', params)
      return res
    },
    // 基础数据
    async getCePingBasicData(context, params) {
      const res = await get('/Study/cepingReport/getCePingBasicData', params)
      return res
    },
    // 知识点掌握详情
    async getTimuKpointInfoByGuid(context, params) {
      const res = await get('Study/tifenjinjie/getTimuKpointInfoByGuid', params)
      return res
    },
    // 知识点掌握详情带强化训练
    async getKpointInfoByGuid(context, params) {
      const res = await get('/Study/cepingReport/getKpointInfoByGuid', params)
      return res
    },
    // 时间
    async getCepingZongPing(context, params) {
      const res = await get('/Study/cepingReport/getCepingZongPing', params)
      return res
    },
    // 所有答题详情
    async getPracticeShitiAndTimeByGuid(context, params) {
      const res = await get('/Study/xkwService/getPracticeShitiAndTimeByGuid', params)
      return res
    },
    // 知识点答题详情
    async getShitiInfoByGuidKpointId(context, params) {
      const res = await get('/Study/tifenjinjie/getShitiInfoByGuidKpointId', params)
      return res
    },
    // 获取知识点做题和看视频时长
    async getKpointVideoTime(context, params) {
      const res = await get('/Study/cepingReport/getKpointVideoTime', params)
      return res
    },
    // 获取正确率对错数量
    async getBasicDataNew(context, params) {
      const res = await get('/Study/cepingReport/getBasicDataNew', params)
      return res
    },
    // 获取知识点明细
    async getKpointMingXi(context, params) {
      const res = await get('/Study/cepingReport/getKpointMingXi', params)
      return res
    },
    // 获取wx sdk config需要信息
    async getWxSdkConfigInfo(context, params) {
      const res = await get('https://wechat.lazyapis.com/WeChatCatWeb/tools/wechat/wechat/jsssdk/ignature', params)
      return res
    },
    // 获取自主测评报告总评
    async getZizhuCepingUserInfo(context, params) {
      const res = await get('/Study/zizhuCeping/getZizhuCepingUserInfo', params)
      return res
    },
    // 获取自主测评正答率对错率
    async getZiZhuBasicData(context, params) {
      const res = await get('/Study/zizhuCeping/getZiZhuBasicData', params)
      return res
    },
    // 获取自主测评明细
    async getZiZhuKpointMingXi(context, params) {
      const res = await get('/Study/zizhuCeping/getZiZhuKpointMingXi', params)
      return res
    },
  }
}
