<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      _gap_time: 0,
      _beforeUnload_time: 0,
      wxSdkConfigInfo:null,
      currentBrowserTitle:'测评报告'
    }
  },
  created() {
    // this.currentUserInfo = this.$session.get('currentUserInfo')
    this.fetchWxSdkConfigInfo()

    this.currentBrowserTitle = document.title
    // this.$message.error(this.currentBrowserTitle)
    },
  destroyed() {
    // 移除监听
    window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
    window.removeEventListener("unload", () => this.unloadHandler());
  },
  mounted() {
    // 监听浏览器关闭
    window.addEventListener("beforeunload", () => this.beforeunloadHandler());
    window.addEventListener("unload", () => this.unloadHandler());
  },
  methods: {
    ...mapActions('testlearn', ['update']),
    ...mapActions("promoteReport", ['getWxSdkConfigInfo']),
    statuFun(num,id){
      this.currentUserInfo = this.$session.get('currentUserInfo')
      this.update({onlineStatus:num,actionId:id,studentId:this.currentUserInfo.studentId}).then(res => {
        console.log(res,'离线')
      })
    },
      // 关闭窗口之前执行
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime();
    },
    unloadHandler() {
      //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      if (this._gap_time <= 5) {
        // logOut() // 退出登录接口 这里应当换为个人的登出方法
        this.statuFun(0,100001)
      } else {
        console.log('刷新1111111111111111111');
      }
    },
    // --------------------
    fetchWxSdkConfigInfo(){
      const data = {
        url:window.location.href.split('#')[0]
        
      }
      this.getWxSdkConfigInfo(data).then((res)=>{
        this.wxSdkConfigInfo = res.code === 10000 ? res.data : null
        if(res.code === 10000){
          this.isWeiXinBrowser()
        }
      })
    },
    isWeiXinBrowser(){//判断是否是微信浏览器
    var ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i)=="micromessenger") {//是微信浏览器
      wx.config({
        debug: false,
        appId: this.wxSdkConfigInfo.appId,
        timestamp: this.wxSdkConfigInfo.timestamp,
        nonceStr: this.wxSdkConfigInfo.noncestr,
        signature: this.wxSdkConfigInfo.signature,
        jsApiList: [
          'updateTimelineShareData',
          'updateAppMessageShareData',
        ]
      });
      
        var that = this
        wx.ready(function () {
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          // 注册分享朋友圈事件。
          wx.updateTimelineShareData({
            title: that.currentBrowserTitle, // 分享标题
            link: window.location.href, // 分享链接，该链接域名必须与当前企业的可信域名一致
            imgUrl: 'https://lanrenhuadong-1251696798.cos.ap-shanghai.myqcloud.com/app_png/favicon.ico', // 分享图标
            success: function () {
              console.log('success!');
            },
            cancel: function () {
              console.log('cancel!');
            }
          });
       
          // 注册分享朋友事件
          wx.updateAppMessageShareData({
            title: that.currentBrowserTitle, // 分享标题
            desc: 'AI自习室',
            link: window.location.href, // 分享链接，该链接域名必须与当前企业的可信域名一致
            imgUrl: 'https://lanrenhuadong-1251696798.cos.ap-shanghai.myqcloud.com/app_png/favicon.ico', // 分享图标
            success: function () {
              console.log('success!');
            },
            cancel: function () {
              console.log('cancel!');
            }
          });
        });
      
        wx.error(function(res){
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
    } else {
        return;
    }
    }
  }
}

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 16px;
}
</style>
