import axios from 'axios'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // request timeout
})

export const baseurl = 'https://www.lazyapis.com/'
// export const baseurl = 'https://z.iotqcloud.com/'
// export const baseurl = 'http://192.168.0.238:8585/'// 程-本地
// export const baseurl = 'http://192.168.0.118:8585' // 邓-本地
// export const baseurl = 'http://192.168.0.101:8585/' // 周-本地


axios.defaults.baseURL = baseurl

// lazykids
export function get(url, params) {
  return axios({
    method: 'Get',
    url,
    params
  })
}
export function post(url, params) {
  return axios({
    url,
    method: 'post',
    params
  })
}

export function postJson(url, data) {
  return axios({
    method: 'post',
    url,
    data,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json;  '
    }
  })
}
export function postFormData(url, data) {
  return axios({
    method: 'post',
    url,
    data,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'multipart/form-data;charset=utf-8'
    }
  })
}

// lazyapis
export function postlogin(url, params) {
  return axios({
    url,
    method: 'post',
    params
  })
}

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true,
    //   loadingType: 'spinner'
    // })
    if (store.getters.token) {
      // let each request carry token
    }
    return config
  },
  () => {
    return Promise.reject('请求数据错误')
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    // Toast.clear()
    return response.data
  },
  error => {
    // Toast.clear()
    // Toast.fail('请求数据错误')
    return Promise.reject('响应错误' + error)
  }
)

export default service
