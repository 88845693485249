
import { get, postJson } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 根据bookid拿列表数据
    async getCategoryByBookId(context, params) {
      // const res = await get('/Study/xkwService/getCategoryByBookId', params)
      const res = await get('/Study/xkwService/getAiZxsCategoryByBookId', params)
      return res
    },
    // 单元测评拿题
    async getPracticeQuestions(context, params) {
      const res = await postJson('/Study/xkwCeping/getPracticeQuestions', params)
      return res
    },
    // 试听测评拿题
    async getShiTingPracticeQuestions(context, params) {
      const res = await postJson('/Study/xkwCeping/getShiTingPracticeQuestions', params)
      return res
    },
    // 测评保存进度
    async submitCePingSave(context, params) {
      const res = await postJson('/Study/xkwCeping/submitCePingSave', params)
      return res
    },
    // 查是否有保存记录
    async getCePingSaveGuid(context, params) {
      // const res = await get('/Study/xkwCeping/getCePingSaveGuid', params)
      const res = await get('/Study/xkwCeping/getCePingSaveGuidNew', params)
      return res
    },
    // 获取保存的试题
    async getSaveCePingShitiByGuid(context, params) {
      const res = await get('/Study/xkwCeping/getSaveCePingShitiByGuid', params)
      return res
    },
    // 清除保存的记录
    async delectCePingSaveGuid(context, params) {
      const res = await get('/Study/xkwCeping/delectCePingSaveGuid', params)
      return res
    },
    // 自主测评拿题
    async getZiZhuCePingQList(context, params) {
      const res = await postJson('/Study/zizhuCeping/getZiZhuCePingQList', params)
      return res
    },
    // 自主测评提交答案
    async submitZiZhuCePing(context, params) {
      const res = await postJson('/Study/zizhuCeping/submitZiZhuCePing', params)
      return res
    },
    // 自主测评拿保存记录的题
    async getZiZhuShitiShitiBySaveGuid(context, params) {
      const res = await get('/Study/zizhuCeping/getZiZhuShitiShitiBySaveGuid', params)
      return res
    },
    // 查询是否有保存的自主测评记录
    async getZiZhuSaveGuid(context, params) {
      const res = await get('/Study/zizhuCeping/getZiZhuSaveGuid', params)
      return res
    }

  }
}

