
import { get } from '@/utils/request'
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // 获取自然拼读列表
    async getWordPingDuType(context, params) {
      const res = await get('/Study/aiWord/getWordPingDuType', params)
      return res
    },
    // 获取拼读视频
    async getPingDuVideoByType(context, params) {
      const res = await get('/Study/aiWord/getPingDuVideoByType', params)
      return res
    },
     //获取音标列表详情
    async getYinBiaoInfoByType(context, params) {
      const res = await get('/Study/aiWord/getYinBiaoInfoByType', params)
      return res
    },
   
    
  }
}
